/* You can add global styles to this file, and also import other style files */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
    margin: 0;
    background-color: #FAFBFC;
}

html {
    height: 100%;
}

body {
    height: 100%;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body, html {
    width: 100%;
    height: 100%;
}

/* FONTS */

/* GILROY */

@font-face {
    font-family: 'gilroybold';
    src: url('assets/fonts/gilroy/gilroy-bold-webfont.woff2') format('woff2'), url('assets/fonts/gilroy/gilroy-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gilroyextrabold';
    src: url('assets/fonts/gilroy/gilroy-extrabold-webfont.woff2') format('woff2'), url('assets/fonts/gilroy/gilroy-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gilroylight';
    src: url('assets/fonts/gilroy/gilroy-light-webfont.woff2') format('woff2'), url('assets/fonts/gilroy/gilroy-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gilroymedium';
    src: url('assets/fonts/gilroy/gilroy-medium-webfont.woff2') format('woff2'), url('assets/fonts/gilroy/gilroy-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gilroyregular';
    src: url('assets/fonts/gilroy/gilroy-regular-webfont.woff2') format('woff2'), url('assets/fonts/gilroy/gilroy-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gilroysemibold';
    src: url('assets/fonts/gilroy/gilroy-semibold-webfont.woff2') format('woff2'), url('assets/fonts/gilroy/gilroy-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* GILROY */

/* CIRCULAR STD */
@font-face {
    font-family: "CircularStd";
    src: url("assets/fonts/circular/CircularStd-Black.eot");
    src: url("assets/fonts/circular/CircularStd-Black.eot?#iefix") format("embedded-opentype"), url("assets/fonts/circular/CircularStd-Black.woff") format("woff"), url("assets/fonts/circular/CircularStd-Black.ttf") format("truetype"), url("assets/fonts/circular/CircularStd-Black.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "CircularStd";
    src: url("assets/fonts/circular/CircularStd-BlackItalic.eot");
    src: url("assets/fonts/circular/CircularStd-BlackItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/circular/CircularStd-BlackItalic.woff") format("woff"), url("assets/fonts/circular/CircularStd-BlackItalic.ttf") format("truetype"), url("assets/fonts/circular/CircularStd-BlackItalic.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "CircularStd";
    src: url("assets/fonts/circular/CircularStd-Bold.eot");
    src: url("assets/fonts/circular/CircularStd-Bold.eot?#iefix") format("embedded-opentype"), url("assets/fonts/circular/CircularStd-Bold.woff") format("woff"), url("assets/fonts/circular/CircularStd-Bold.ttf") format("truetype"), url("assets/fonts/circular/CircularStd-Bold.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "CircularStd";
    src: url("assets/fonts/circular/CircularStd-BoldItalic.eot");
    src: url("assets/fonts/circular/CircularStd-BoldItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/circular/CircularStd-BoldItalic.woff") format("woff"), url("assets/fonts/circular/CircularStd-BoldItalic.ttf") format("truetype"), url("assets/fonts/circular/CircularStd-BoldItalic.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "CircularStd";
    src: url("assets/fonts/circular/CircularStd-Medium.eot");
    src: url("assets/fonts/circular/CircularStd-Medium.eot?#iefix") format("embedded-opentype"), url("assets/fonts/circular/CircularStd-Medium.woff") format("woff"), url("assets/fonts/circular/CircularStd-Medium.ttf") format("truetype"), url("assets/fonts/circular/CircularStd-Medium.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "CircularStd";
    src: url("assets/fonts/circular/CircularStd-MediumItalic.eot");
    src: url("assets/fonts/circular/CircularStd-MediumItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/circular/CircularStd-MediumItalic.woff") format("woff"), url("assets/fonts/circular/CircularStd-MediumItalic.ttf") format("truetype"), url("assets/fonts/circular/CircularStd-MediumItalic.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "CircularStd";
    src: url("assets/fonts/circular/CircularStd-Book.eot");
    src: url("assets/fonts/circular/CircularStd-Book.eot?#iefix") format("embedded-opentype"), url("assets/fonts/circular/CircularStd-Book.woff") format("woff"), url("assets/fonts/circular/CircularStd-Book.ttf") format("truetype"), url("assets/fonts/circular/CircularStd-Book.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "CircularStd";
    src: url("assets/fonts/circular/CircularStd-BookItalic.eot");
    src: url("assets/fonts/circular/CircularStd-BookItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/circular/CircularStd-BookItalic.woff") format("woff"), url("assets/fonts/circular/CircularStd-BookItalic.ttf") format("truetype"), url("assets/fonts/circular/CircularStd-BookItalic.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
    font-weight: 400;
    font-style: italic;
}
/* CIRCULAR STD */